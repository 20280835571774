<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <flixCheckPermissions role="profile" permission="3" error>
            <deleteAccount />
          </flixCheckPermissions>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    deleteAccount () { return import('@/components/membership/deleteAccount.vue') }
  }
}
</script>
